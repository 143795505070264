
import { Companies, Courses, Events, Quotes } from "@/api";
import { QuoteCompany, QuoteCompanyCourse, QuotePrivate } from "@/api/types";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

interface QuoteInfo {
  id: number;
  desiredDate: string;
  companyName: string;
  companyType: number;
  companyId: string;
  clientName: string;
  ccAgent: string;
  statusName: string;
  totalPrice: number;
  premiseTypeId: number;
  travelCost: number;
  accomodationCost: number;
  instructorFee: number;
  lunchFee: number;
  courierFee: number;
  carHireFee: number;
  courses: {
    id: number;
    qtyDelegates: number;
    name: string;
    discountedPrice: number;
    newPrice: number;
    price: number;
  }[];
  comments: string;
}

@Component({
  components: { ValidationObserver, ValidationProvider }
})
export default class QuoteFormModal extends Vue {
  @Prop()
  quote!: any;

  quoteInfo: QuoteInfo = {} as QuoteInfo;
  isPrivate = false;
  courses: any[] = [];
  officeTypes: any = [];
  errors = [];

  initialCourse = {
    courseId: undefined,
    qtyDelegates: undefined,
    discountedPrice: undefined,
    coursePrice: undefined
  };

  quotePrivate: QuotePrivate = {
    fullName: "",
    personId: "",
    companyId: undefined,
    typeId: 1,
    courses: [{ ...this.initialCourse }]
  };

  quoteCompany: QuoteCompany = {
    companyName: "",
    address: "",
    courses: [{ ...this.initialCourse }],
    coursePrice: undefined,
    discountedPrice: undefined,
    premiseTypeId: 2,
    travelCost: undefined,
    accomodationCost: undefined,
    instructorFee: undefined,
    lunchFee: undefined,
    courierFee: undefined,
    carHireFee: undefined,
    companyId: undefined,
    typeId: 2,
    comments: ""
  };

  async created() {
    try {
      [this.courses, this.officeTypes] = await Promise.all([
        Courses.listWithoutPagination(),
        Events.officeTypes()
      ]);

      this.fetchQuote();
      // this.fetchCompanyInfo(this.companyId);
    } catch (e) {
      const err = e as any;
      await this.$error(err.errors);
    }
  }

  @Watch("quote.id", { deep: true })
  fetchQuoteHandler() {
    this.fetchQuote();
  }

  async fetchQuote() {
    this.quoteInfo = await Quotes.fetchQuoteById(this.quote.id);

    this.isPrivate = +this.quoteInfo?.companyType == 2;

    this.fetchCompanyInfo(this.quoteInfo.companyId);

    if (this.isPrivate) {
      this.quotePrivate.courses = this.quoteInfo.courses.map(course => ({
        courseId: this.courses.find((item: any) => course.name === item.name)
          ?.id,
        qtyDelegates: course.qtyDelegates,
        discountedPrice:
          +course.newPrice !== +course.price ? course.newPrice : undefined,
        coursePrice: course.price
      }));
    } else {
      // this.quoteCompany = { ...this.quoteInfo } as any;
      this.quoteCompany.courses = this.quoteInfo.courses.map(course => ({
        courseId: this.courses.find((item: any) => course.name === item.name)
          ?.id,
        qtyDelegates: course.qtyDelegates,
        discountedPrice:
          +course.newPrice !== +course.price ? course.newPrice : undefined,
        coursePrice: course.price
      }));
      this.quoteCompany.instructorFee = this.quoteInfo.instructorFee;
      this.quoteCompany.premiseTypeId = this.quoteInfo.premiseTypeId;
      this.quoteCompany.travelCost = this.quoteInfo.travelCost;
      this.quoteCompany.accomodationCost = this.quoteInfo.accomodationCost;
      this.quoteCompany.lunchFee = this.quoteInfo.lunchFee;
      this.quoteCompany.courierFee = this.quoteInfo.courierFee;
      this.quoteCompany.carHireFee = this.quoteInfo.carHireFee;
      this.quoteCompany.comments = this.quoteInfo.comments;
    }
  }

  async fetchCompanyInfo(id: string) {
    if (!this.isPrivate) {
      const companyInfo = await Companies.info(id);
      this.quoteCompany.companyName = companyInfo.name;
      this.quoteCompany.address = companyInfo.address;
      this.quoteCompany.companyId = id;
    } else {
      const companyInfoPrivate = await Companies.infoPrivate(id);
      this.quotePrivate.fullName = companyInfoPrivate.name;
      this.quotePrivate.personId = companyInfoPrivate.passport;
      this.quotePrivate.companyId = id;
    }
  }

  async setCoursePrice(course: QuoteCompanyCourse) {
    if (course.courseId) {
      const selectedCourse = await this.onCourseUpdate(course.courseId);
      course.coursePrice = selectedCourse;
    }
  }

  addCourse(company: any) {
    company.courses.push({ ...this.initialCourse });
  }

  removeCourse(
    company: QuotePrivate | QuoteCompany,
    courseToDelete: QuoteCompanyCourse
  ) {
    company.courses = company.courses.filter(
      course => course !== courseToDelete
    );
  }

  async onCourseUpdate(id: number) {
    try {
      const course = await Courses.info(id.toString());
      return course.price;
    } catch (e) {
      const err = e as any;
      await this.$error(err.errors);
    }
  }

  async transformEmptyValues() {
    if (this.isPrivate) {
      // this.quotePrivate.courses.forEach(course => {
      //   course.discountedPrice = course.discountedPrice
      //     ? course.discountedPrice
      //     : 0;
      // });
    } else {
      // this.quoteCompany.courses.forEach(course => {
      //   course.discountedPrice = course.discountedPrice
      //     ? course.discountedPrice
      //     : 0;
      //   course.qtyDelegates = course.qtyDelegates ? course.qtyDelegates : 1;
      // });
      this.quoteCompany.travelCost = this.quoteCompany.travelCost
        ? this.quoteCompany.travelCost
        : 0;
      this.quoteCompany.accomodationCost = this.quoteCompany.accomodationCost
        ? this.quoteCompany.accomodationCost
        : 0;
      this.quoteCompany.instructorFee = this.quoteCompany.instructorFee
        ? this.quoteCompany.instructorFee
        : 0;
      this.quoteCompany.lunchFee = this.quoteCompany.lunchFee
        ? this.quoteCompany.lunchFee
        : 0;
      this.quoteCompany.courierFee = this.quoteCompany.courierFee
        ? this.quoteCompany.courierFee
        : 0;
      this.quoteCompany.carHireFee = this.quoteCompany.carHireFee
        ? this.quoteCompany.carHireFee
        : 0;
    }
  }

  async onCloseModal() {
    this.$modal.hide("quote-form-modal");
    this.$emit("close");
    this.isPrivate = false;
    this.quoteCompany = {
      companyName: "",
      address: "",
      courses: [{ ...this.initialCourse }],
      coursePrice: undefined,
      discountedPrice: undefined,
      premiseTypeId: 2,
      travelCost: undefined,
      accomodationCost: undefined,
      instructorFee: undefined,
      lunchFee: undefined,
      courierFee: undefined,
      carHireFee: undefined,
      companyId: undefined,
      typeId: 2,
      comments: ""
    };
    this.quotePrivate = {
      fullName: "",
      personId: "",
      companyId: undefined,
      typeId: 1,
      courses: [{ ...this.initialCourse }]
    };
  }

  async postCreateQuote() {
    try {
      const valid: any = this.isPrivate
        ? await (this.$refs.privateObserver as Vue & {
            validate: () => boolean;
          }).validate()
        : await (this.$refs.companyObserver as Vue & {
            validate: () => boolean;
          }).validate();

      if (valid) {
        await this.transformEmptyValues();

        const data = this.transformDataToRequestBody();

        await Companies.updateQuote(data, this.quote.id).then(() => {
          this.$emit("updated");
          this.onCloseModal();
        });
      }
    } catch (e) {
      const err = e as any;
      const errText = JSON.parse(await err.response.data.text());

      await this.$error(
        (errText?.result?.message && { err: errText.result.message }) || {
          err: "Something went wrong"
        }
      );
    }
  }

  async postPreviewQuote() {
    try {
      const valid: any = this.isPrivate
        ? await (this.$refs.privateObserver as Vue & {
            validate: () => boolean;
          }).validate()
        : await (this.$refs.companyObserver as Vue & {
            validate: () => boolean;
          }).validate();

      if (valid) {
        await this.transformEmptyValues();

        const data = this.transformDataToRequestBody();

        await Companies.previewQuote(data).then((response: any) => {
          const file = new Blob([response.data], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL, "_blank");
        });
      }
    } catch (e) {
      const err = e as any;
      const errText = JSON.parse(await err.response.data.text());

      await this.$error(
        (errText?.result?.message && { err: errText.result.message }) || {
          err: "Something went wrong"
        }
      );
    }
  }

  transformDataToRequestBody() {
    const quote: QuoteCompany | QuotePrivate = this.isPrivate
      ? this.quotePrivate
      : this.quoteCompany;

    const courses = [...JSON.parse(JSON.stringify(quote.courses))];

    const data = JSON.parse(JSON.stringify(quote));

    data.courses = courses.reduce((acc, cur) => {
      if (acc[cur.courseId] && acc[cur.courseId].length) {
        acc[cur.courseId].push({
          price: cur.discountedPrice ? cur.discountedPrice : cur.coursePrice,
          qtyDelegates: cur.qtyDelegates
        });
      } else {
        acc[cur.courseId] = [
          {
            price: cur.discountedPrice ? cur.discountedPrice : cur.coursePrice,
            qtyDelegates: cur.qtyDelegates
          }
        ];
      }

      return acc;
    }, {});
    return data;
  }
}
