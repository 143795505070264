
import { Companies, Quotes } from "@/api";
import TitleHeader from "@/components/common/TitleHeader.vue";
import QuoteFormModal from "@/components/companies/QuoteFormModal.vue";
import moment from "moment";
import { nextTick } from "process";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    TitleHeader,
    QuoteFormModal
  }
})
export default class QuotesListPage extends Vue {
  filters: any = {
    name: "",
    dateFrom: moment()
      .startOf("month")
      .format("YYYY-MM-DD"),
    dateTo: moment()
      .endOf("month")
      .format("YYYY-MM-DD"),
    statusId: undefined,
    companyName: undefined,
    ccAgent: undefined
  };
  timeout = 0;
  quotes = [];
  page = 1;
  options = {};
  itemsPerPage = 10;
  total = 0;
  menu = false;
  menuSecond = false;
  statuses = [
    {
      id: 1,
      name: "Pending"
    },
    {
      id: 2,
      name: "Accepted"
    },
    {
      id: 3,
      name: "Declined"
    }
  ];
  headers = [
    // {
    //   text: "Quote ID",
    //   value: "id",
    //   width: "100px",
    // },
    {
      text: "Quote Date",
      value: "desiredDate",
      width: "150px"
    },
    {
      text: "Company Name",
      value: "companyName",
      width: "250px"
    },
    {
      text: "Client Name",
      value: "clientName",
      width: "150px"
    },
    {
      text: "CC Agent Name",
      value: "ccAgent",
      width: "200px"
    },
    {
      text: "Course Name",
      value: "courses"
    },
    {
      text: "Total Price",
      value: "totalPrice",
      width: "130px"
    },
    {
      text: "Status",
      value: "statusName",
      width: "120px"
    },
    {
      text: "Manage",
      value: "manage",
      width: "200px"
    },
    {
      text: "Actions",
      value: "actions",
      width: "200px"
    }
  ];
  items = [];
  companies = [];
  ccAgents = [];
  selectedQuote: any = null;

  @Watch("filters", { deep: true })
  async onFilterNameChange() {
    await this.loadQuotesData(1, this.filters);
  }
  @Watch("page", { deep: true })
  async onPageNameChange(val: any) {
    await this.loadQuotesData(val, this.filters);
  }
  async loadQuotesData(page = 1, filters: any) {
    try {
      const response: any = await Quotes.loadQuotesData(page, filters);
      this.items = response.data;
      this.itemsPerPage = response.meta.perPage;
      this.page = response.meta.currentPage;
      this.total = response.meta.total;
    } catch (e) {
      const err = e as any;
      await this.$error(err.errors);
    }
  }
  async created() {
    try {
      const { name, statusId, companyName, ccAgent } = this.filters;
      await this.loadQuotesData(1, { name, statusId, companyName, ccAgent });
      this.companies = await Companies.listWithoutPagination();
      this.ccAgents = await Companies.loadCcAgents();
    } catch (e) {
      const err = e as any;
      await this.$error(err.errors);
    }
  }
  async acceptQuote(id: number) {
    try {
      await Quotes.accept(id);
      await this.loadQuotesData(1, this.filters);
    } catch (e) {
      const err = e as any;
      await this.$error(err.errors);
    }
  }
  async declineQuote(id: number) {
    try {
      await Quotes.decline(id);
      await this.loadQuotesData(1, this.filters);
    } catch (e) {
      const err = e as any;
      await this.$error(err.errors);
    }
  }
  async resendQuote(id: number) {
    try {
      await Quotes.resendEmail(id);
      await this.$success("<strong>Success!</strong>Email has been resent!");
    } catch (e) {
      const err = e as any;
      await this.$error(err.errors);
    }
  }
  async getSingleQuote(id: number) {
    try {
      const data: any = await Quotes.getSingleQuote(id);
      const file = new Blob([data.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, "_blank");
    } catch (e) {
      const err = e as any;
      await this.$error(err.errors);
    }
  }
  @Watch("options", { deep: true })
  async onOptionsChange(val: any) {
    await this.loadQuotesData(val.page, this.filters);
  }

  formatDate(date: string) {
    return moment(date).format("DD/MM/YYYY");
  }
  async onExport() {
    try {
      const fileName = `quotes-${moment().format("DD/MM/YYYY")}.xlsx`;
      const data = await Quotes.exportQuotes(this.filters);
      const downloadUrl = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (e) {
      const err = e as any;
      await this.$error(err.errors);
    }
  }
  editQuote(quote: any) {
    this.selectedQuote = quote;

    nextTick(() => {
      this.$modal.show("quote-form-modal");
    });
  }
  onUpdated() {
    this.loadQuotesData(this.page, this.filters);
    this.$success("<strong>Success!</strong>Quote has been updated!");
  }
  formatCoursesNames(courses: any[]) {
    const coursesList = courses.map(course => course.name);
    const uniqueCoursesList = Array.from(new Set(coursesList));

    return uniqueCoursesList.join(",<br />");
  }
}
